html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1.5; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/***********************************************
                    MARGIN
***********************************************/
.m0 {
  margin: 0 !important; }

.m3 {
  margin: 3px !important; }

.m5 {
  margin: 5px !important; }

.m8 {
  margin: 8px !important; }

.m10 {
  margin: 10px !important; }

.m15 {
  margin: 15px !important; }

.m20 {
  margin: 20px !important; }

.m25 {
  margin: 25px !important; }

.m30 {
  margin: 30px !important; }

.m35 {
  margin: 35px !important; }

.m40 {
  margin: 40px !important; }

.m45 {
  margin: 45px !important; }

.m50 {
  margin: 50px !important; }

.m55 {
  margin: 55px !important; }

.m60 {
  margin: 60px !important; }

.m65 {
  margin: 65px !important; }

.m70 {
  margin: 70px !important; }

.m75 {
  margin: 75px !important; }

.m80 {
  margin: 80px !important; }

.m85 {
  margin: 85px !important; }

.m90 {
  margin: 90px !important; }

.m95 {
  margin: 95px !important; }

.m100 {
  margin: 100px !important; }

/***********************************************
                MARGIN LEFT
***********************************************/
.ml0 {
  margin-left: 0 !important; }

.ml3 {
  margin-left: 3px !important; }

.ml5 {
  margin-left: 5px !important; }

.ml8 {
  margin-left: 8px !important; }

.ml10 {
  margin-left: 10px !important; }

.ml15 {
  margin-left: 15px !important; }

.ml20 {
  margin-left: 20px !important; }

.ml25 {
  margin-left: 25px !important; }

.ml30 {
  margin-left: 30px !important; }

.ml35 {
  margin-left: 35px !important; }

.ml40 {
  margin-left: 40px !important; }

.ml45 {
  margin-left: 45px !important; }

.ml50 {
  margin-left: 50px !important; }

.ml55 {
  margin-left: 55px !important; }

.ml60 {
  margin-left: 60px !important; }

.ml65 {
  margin-left: 65px !important; }

.ml70 {
  margin-left: 70px !important; }

.ml75 {
  margin-left: 75px !important; }

.ml80 {
  margin-left: 80px !important; }

.ml85 {
  margin-left: 85px !important; }

.ml90 {
  margin-left: 90px !important; }

.ml95 {
  margin-left: 95px !important; }

.ml100 {
  margin-left: 100px !important; }

/***********************************************
                MARGIN RIGHT
***********************************************/
.mr0 {
  margin-right: 0 !important; }

.mr3 {
  margin-right: 3px !important; }

.mr5 {
  margin-right: 5px !important; }

.mr8 {
  margin-right: 8px !important; }

.mr10 {
  margin-right: 10px !important; }

.mr15 {
  margin-right: 15px !important; }

.mr20 {
  margin-right: 20px !important; }

.mr25 {
  margin-right: 25px !important; }

.mr30 {
  margin-right: 30px !important; }

.mr35 {
  margin-right: 35px !important; }

.mr40 {
  margin-right: 40px !important; }

.mr45 {
  margin-right: 45px !important; }

.mr50 {
  margin-right: 50px !important; }

.mr55 {
  margin-right: 55px !important; }

.mr60 {
  margin-right: 60px !important; }

.mr65 {
  margin-right: 65px !important; }

.mr70 {
  margin-right: 70px !important; }

.mr75 {
  margin-right: 75px !important; }

.mr80 {
  margin-right: 80px !important; }

.mr85 {
  margin-right: 85px !important; }

.mr90 {
  margin-right: 90px !important; }

.mr95 {
  margin-right: 95px !important; }

.mr100 {
  margin-right: 100px !important; }

/***********************************************
                MARGIN BOTTOM
***********************************************/
.mb0 {
  margin-bottom: 0 !important; }

.mb3 {
  margin-bottom: 3px !important; }

.mb5 {
  margin-bottom: 5px !important; }

.mb8 {
  margin-bottom: 8px !important; }

.mb10 {
  margin-bottom: 10px !important; }

.mb15 {
  margin-bottom: 15px !important; }

.mb20 {
  margin-bottom: 20px !important; }

.mb25 {
  margin-bottom: 25px !important; }

.mb30 {
  margin-bottom: 30px !important; }

.mb35 {
  margin-bottom: 35px !important; }

.mb40 {
  margin-bottom: 40px !important; }

.mb45 {
  margin-bottom: 45px !important; }

.mb50 {
  margin-bottom: 50px !important; }

.mb55 {
  margin-bottom: 55px !important; }

.mb60 {
  margin-bottom: 60px !important; }

.mb65 {
  margin-bottom: 65px !important; }

.mb70 {
  margin-bottom: 70px !important; }

.mb75 {
  margin-bottom: 75px !important; }

.mb80 {
  margin-bottom: 80px !important; }

.mb85 {
  margin-bottom: 85px !important; }

.mb90 {
  margin-bottom: 90px !important; }

.mb95 {
  margin-bottom: 95px !important; }

.mb100 {
  margin-bottom: 100px !important; }

/***********************************************
                MARGIN TOP
***********************************************/
.mt0 {
  margin-top: 0 !important; }

.mt3 {
  margin-top: 3px !important; }

.mt5 {
  margin-top: 5px !important; }

.mt8 {
  margin-top: 8px !important; }

.mt10 {
  margin-top: 10px !important; }

.mt15 {
  margin-top: 15px !important; }

.mt20 {
  margin-top: 20px !important; }

.mt25 {
  margin-top: 25px !important; }

.mt30 {
  margin-top: 30px !important; }

.mt35 {
  margin-top: 35px !important; }

.mt40 {
  margin-top: 40px !important; }

.mt45 {
  margin-top: 45px !important; }

.mt50 {
  margin-top: 50px !important; }

.mt55 {
  margin-top: 55px !important; }

.mt60 {
  margin-top: 60px !important; }

.mt65 {
  margin-top: 65px !important; }

.mt70 {
  margin-top: 70px !important; }

.mt75 {
  margin-top: 75px !important; }

.mt80 {
  margin-top: 80px !important; }

.mt85 {
  margin-top: 85px !important; }

.mt90 {
  margin-top: 90px !important; }

.mt95 {
  margin-top: 95px !important; }

.mt100 {
  margin-top: 100px !important; }

/***********************************************
                    PADDING
***********************************************/
.p0 {
  padding: 0 !important; }

.p3 {
  padding: 3px !important; }

.p5 {
  padding: 5px !important; }

.p8 {
  padding: 8px !important; }

.p10 {
  padding: 10px !important; }

.p15 {
  padding: 15px !important; }

.p20 {
  padding: 20px !important; }

.p25 {
  padding: 25px !important; }

.p30 {
  padding: 30px !important; }

.p35 {
  padding: 35px !important; }

.p40 {
  padding: 40px !important; }

.p45 {
  padding: 45px !important; }

.p50 {
  padding: 50px !important; }

.p55 {
  padding: 55px !important; }

.p60 {
  padding: 60px !important; }

.p65 {
  padding: 65px !important; }

.p70 {
  padding: 70px !important; }

.p75 {
  padding: 75px !important; }

.p80 {
  padding: 80px !important; }

.p85 {
  padding: 85px !important; }

.p90 {
  padding: 90px !important; }

.p95 {
  padding: 95px !important; }

.p100 {
  padding: 100px !important; }

/***********************************************
                PADDING LEFT
***********************************************/
.pl0 {
  padding-left: 0 !important; }

.pl3 {
  padding-left: 3px !important; }

.pl5 {
  padding-left: 5px !important; }

.pl8 {
  padding-left: 8px !important; }

.pl10 {
  padding-left: 10px !important; }

.pl15 {
  padding-left: 15px !important; }

.pl20 {
  padding-left: 20px !important; }

.pl25 {
  padding-left: 25px !important; }

.pl30 {
  padding-left: 30px !important; }

.pl35 {
  padding-left: 35px !important; }

.pl40 {
  padding-left: 40px !important; }

.pl45 {
  padding-left: 45px !important; }

.pl50 {
  padding-left: 50px !important; }

.pl55 {
  padding-left: 55px !important; }

.pl60 {
  padding-left: 60px !important; }

.pl65 {
  padding-left: 65px !important; }

.pl70 {
  padding-left: 70px !important; }

.pl75 {
  padding-left: 75px !important; }

.pl80 {
  padding-left: 80px !important; }

.pl85 {
  padding-left: 85px !important; }

.pl90 {
  padding-left: 90px !important; }

.pl95 {
  padding-left: 95px !important; }

.pl100 {
  padding-left: 100px !important; }

/***********************************************
                PADDING RIGHT
***********************************************/
.pr0 {
  padding-right: 0 !important; }

.pr3 {
  padding-right: 3px !important; }

.pr5 {
  padding-right: 5px !important; }

.pr8 {
  padding-right: 8px !important; }

.pr10 {
  padding-right: 10px !important; }

.pr15 {
  padding-right: 15px !important; }

.pr20 {
  padding-right: 20px !important; }

.pr25 {
  padding-right: 25px !important; }

.pr30 {
  padding-right: 30px !important; }

.pr35 {
  padding-right: 35px !important; }

.pr40 {
  padding-right: 40px !important; }

.pr45 {
  padding-right: 45px !important; }

.pr50 {
  padding-right: 50px !important; }

.pr55 {
  padding-right: 55px !important; }

.pr60 {
  padding-right: 60px !important; }

.pr65 {
  padding-right: 65px !important; }

.pr70 {
  padding-right: 70px !important; }

.pr75 {
  padding-right: 75px !important; }

.pr80 {
  padding-right: 80px !important; }

.pr85 {
  padding-right: 85px !important; }

.pr90 {
  padding-right: 90px !important; }

.pr95 {
  padding-right: 95px !important; }

.pr100 {
  padding-right: 100px !important; }

/***********************************************
                PADDING BOTTOM
***********************************************/
.pb0 {
  padding-bottom: 0 !important; }

.pb3 {
  padding-bottom: 3px !important; }

.pb5 {
  padding-bottom: 5px !important; }

.pb8 {
  padding-bottom: 8px !important; }

.pb10 {
  padding-bottom: 10px !important; }

.pb15 {
  padding-bottom: 15px !important; }

.pb20 {
  padding-bottom: 20px !important; }

.pb25 {
  padding-bottom: 25px !important; }

.pb30 {
  padding-bottom: 30px !important; }

.pb35 {
  padding-bottom: 35px !important; }

.pb40 {
  padding-bottom: 40px !important; }

.pb45 {
  padding-bottom: 45px !important; }

.pb50 {
  padding-bottom: 50px !important; }

.pb55 {
  padding-bottom: 55px !important; }

.pb60 {
  padding-bottom: 60px !important; }

.pb65 {
  padding-bottom: 65px !important; }

.pb70 {
  padding-bottom: 70px !important; }

.pb75 {
  padding-bottom: 75px !important; }

.pb80 {
  padding-bottom: 80px !important; }

.pb85 {
  padding-bottom: 85px !important; }

.pb90 {
  padding-bottom: 90px !important; }

.pb95 {
  padding-bottom: 95px !important; }

.pb100 {
  padding-bottom: 100px !important; }

/***********************************************
                PADDING TOP
***********************************************/
.pt0 {
  padding-top: 0 !important; }

.pt3 {
  padding-top: 3px !important; }

.pt5 {
  padding-top: 5px !important; }

.pt8 {
  padding-top: 8px !important; }

.pt10 {
  padding-top: 10px !important; }

.pt15 {
  padding-top: 15px !important; }

.pt20 {
  padding-top: 20px !important; }

.pt25 {
  padding-top: 25px !important; }

.pt30 {
  padding-top: 30px !important; }

.pt35 {
  padding-top: 35px !important; }

.pt40 {
  padding-top: 40px !important; }

.pt45 {
  padding-top: 45px !important; }

.pt50 {
  padding-top: 50px !important; }

.pt55 {
  padding-top: 55px !important; }

.pt60 {
  padding-top: 60px !important; }

.pt65 {
  padding-top: 65px !important; }

.pt70 {
  padding-top: 70px !important; }

.pt75 {
  padding-top: 75px !important; }

.pt80 {
  padding-top: 80px !important; }

.pt85 {
  padding-top: 85px !important; }

.pt90 {
  padding-top: 90px !important; }

.pt95 {
  padding-top: 95px !important; }

.pt100 {
  padding-top: 100px !important; }

.loader {
  position: absolute;
  top: 50%;
  left: 45%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -mos-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  /* disable selection and cursor changes */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default; }

.circle {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center; }

.circle {
  width: 150px;
  height: 150px;
  margin-top: -75px;
  margin-left: -50px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background: #F2F2EF;
  z-index: 1;
  font-family: 'Uni Sans';
  font-size: 6em;
  font-weight: 300;
  color: #303030;
  line-height: 1.35em;
  -webkit-animation: circle 1.5s infinite ease-in-out;
  -moz-animation: circle 1.5s infinite ease-in-out;
  -mos-animation: circle 1.5s infinite ease-in-out;
  -o-animation: circle 1.5s infinite ease-in-out;
  animation: circle 1.5s infinite ease-in-out; }

.scircle {
  position: absolute;
  top: -25px;
  left: -23px;
  width: 200px;
  height: 200px;
  -webkit-animation: scircle 1.5s ease-in-out infinite;
  -moz-animation: scircle 1.5s ease-in-out infinite;
  -mos-animation: scircle 1.5s ease-in-out infinite;
  -o-animation: scircle 1.5s ease-in-out infinite;
  -webkit-animation: scircle 1.5s ease-in-out infinite; }

.scircle {
  -webkit-animation: scircle2 1.5s ease-in-out infinite;
  -moz-animation: scircle2 1.5s ease-in-out infinite;
  -mos-animation: scircle2 1.5s ease-in-out infinite;
  -o-animation: scircle2 1.5s ease-in-out infinite;
  -webkit-animation: scircle2 1.5s ease-in-out infinite; }

.scircle:before {
  content: '';
  position: absolute;
  width: .1em;
  height: .1em;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
  background: #213e96; }

.scircle:after {
  content: '';
  position: absolute;
  top: 190px;
  width: .1em;
  height: .1em;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
  background: #213e96; }

@-webkit-keyframes circle {
  0% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50);
    -moz-opacity: 0.5;
    -khtml-opacity: 0.5;
    opacity: 0.5; }
  45%, 50%, 55% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1; }
  100% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50);
    -moz-opacity: 0.5;
    -khtml-opacity: 0.5;
    opacity: 0.5; } }

@-moz-keyframes circle {
  0% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50);
    -moz-opacity: 0.5;
    -khtml-opacity: 0.5;
    opacity: 0.5; }
  45%, 50%, 55% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1; }
  100% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50);
    -moz-opacity: 0.5;
    -khtml-opacity: 0.5;
    opacity: 0.5; } }

@-mos-keyframes circle {
  0% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50);
    -moz-opacity: 0.5;
    -khtml-opacity: 0.5;
    opacity: 0.5; }
  45%, 50%, 55% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1; }
  100% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50);
    -moz-opacity: 0.5;
    -khtml-opacity: 0.5;
    opacity: 0.5; } }

@-o-keyframes circle {
  0% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50);
    -moz-opacity: 0.5;
    -khtml-opacity: 0.5;
    opacity: 0.5; }
  45%, 50%, 55% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1; }
  100% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50);
    -moz-opacity: 0.5;
    -khtml-opacity: 0.5;
    opacity: 0.5; } }

@keyframes circle {
  0% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50);
    -moz-opacity: 0.5;
    -khtml-opacity: 0.5;
    opacity: 0.5; }
  45%, 50%, 55% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1; }
  100% {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50);
    -moz-opacity: 0.5;
    -khtml-opacity: 0.5;
    opacity: 0.5; } }

@-webkit-keyframes scircle {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@-moz-keyframes scircle {
  0% {
    -moz-transform: rotate(0deg); }
  100% {
    -moz-transform: rotate(360deg); } }

@-mos-keyframes scircle {
  0% {
    -mos-transform: rotate(0deg); }
  100% {
    -mos-transform: rotate(360deg); } }

@-o-keyframes scircle {
  0% {
    -o-transform: rotate(0deg); }
  100% {
    -o-transform: rotate(360deg); } }

@keyframes scircle {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@-webkit-keyframes scircle2 {
  0% {
    -webkit-transform: rotate(90deg); }
  100% {
    -webkit-transform: rotate(450deg); } }

@-moz-keyframes scircle2 {
  0% {
    -moz-transform: rotate(90deg); }
  100% {
    -moz-transform: rotate(450deg); } }

@-mos-keyframes scircle2 {
  0% {
    -mos-transform: rotate(90deg); }
  100% {
    -mos-transform: rotate(450deg); } }

@-o-keyframes scircle2 {
  0% {
    -o-transform: rotate(90deg); }
  100% {
    -o-transform: rotate(450deg); } }

@keyframes scircle2 {
  0% {
    transform: rotate(90deg); }
  100% {
    transform: rotate(450deg); } }

.lumi-md-1 {
  width: 8.3333333%; }

.lumi-md-2 {
  width: 16.666666667%; }

.lumi-md-3 {
  width: 25%; }

.lumi-md-4 {
  width: 33.3333333%; }

.lumi-md-5 {
  width: 41.6666667%; }

.lumi-md-6 {
  width: 50%; }

.lumi-md-7 {
  width: 58.3333333%; }

.lumi-md-8 {
  width: 66.6666666%; }

.lumi-md-9 {
  width: 75%; }

.lumi-md-10 {
  width: 83.3333333%; }

.lumi-md-12 {
  width: 100%; }

.lumi-md-1, .lumi-md-2, .lumi-md-3, .lumi-md-4, .lumi-md-5, .lumi-md-6, .lumi-md-7, .lumi-md-8, .lumi-md-9, .lumi-md-10, .lumi-md-11, .lumi-md-12 {
  float: left; }

.lumi-xs-1, .lumi-sm-1, .lumi-md-1, .lumi-lg-1, .lumi-xlg-1,
.lumi-xs-2, .lumi-sm-2, .lumi-md-2, .lumi-lg-2, .lumi-xlg-2,
.lumi-xs-3, .lumi-sm-3, .lumi-md-3, .lumi-lg-3, .lumi-xlg-3,
.lumi-xs-4, .lumi-sm-4, .lumi-md-4, .lumi-lg-4, .lumi-xlg-4,
.lumi-xs-5, .lumi-sm-5, .lumi-md-5, .lumi-lg-5, .lumi-xlg-5,
.lumi-xs-6, .lumi-sm-6, .lumi-md-6, .lumi-lg-6, .lumi-xlg-6,
.lumi-xs-7, .lumi-sm-7, .lumi-md-7, .lumi-lg-7, .lumi-xlg-7,
.lumi-xs-8, .lumi-sm-8, .lumi-md-8, .lumi-lg-8, .lumi-xlg-8,
.lumi-xs-9, .lumi-sm-9, .lumi-md-9, .lumi-lg-9, .lumi-xlg-9,
.lumi-xs-10, .lumi-sm-10, .lumi-md-10, .lumi-lg-10, .lumi-xlg-10,
.lumi-xs-11, .lumi-sm-11, .lumi-md-11, .lumi-lg-11, .lumi-xlg-11,
.lumi-xs-12, .lumi-sm-12, .lumi-md-12, .lumi-lg-12, .lumi-xlg-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.container {
  width: 1140px;
  margin: 0 auto; }

.containerfluid {
  width: 100%;
  padding-right: 30px;
  padding-left: 30px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.alignitemscenter {
  align-items: center !important; }

@media (max-width: 767.98px) {
  .containerfluid {
    padding-left: 15px;
    padding-right: 15px; }
  .hiddenXs {
    display: none !important; }
  .container {
    width: 320px !important; }
  .lumi-xs-1 {
    width: 8.3333333%; }
  .lumi-xs-2 {
    width: 16.666666667%; }
  .lumi-xs-3 {
    width: 25%; }
  .lumi-xs-4 {
    width: 33.3333333%; }
  .lumi-xs-5 {
    width: 41.6666667%; }
  .lumi-xs-6 {
    width: 50%; }
  .lumi-xs-7 {
    width: 58.3333333%; }
  .lumi-xs-8 {
    width: 66.6666666%; }
  .lumi-xs-9 {
    width: 75%; }
  .lumi-xs-10 {
    width: 83.3333333%; }
  .lumi-xs-11 {
    width: 91.7%; }
  .lumi-xs-12 {
    width: 100%; } }

@media (min-width: 767.99px) and (max-width: 835.98px) {
  .container {
    max-width: 750px !important; }
  .lumi-sm-1 {
    width: 8.3333333%; }
  .lumi-sm-2 {
    width: 16.666666667%; }
  .lumi-sm-3 {
    width: 25%; }
  .lumi-sm-4 {
    width: 33.3333333%; }
  .lumi-sm-5 {
    width: 41.6666667%; }
  .lumi-sm-6 {
    width: 50%; }
  .lumi-sm-7 {
    width: 58.3333333%; }
  .lumi-sm-8 {
    width: 66.6666666%; }
  .lumi-sm-9 {
    width: 75%; }
  .lumi-sm-10 {
    width: 83.3333333%; }
  .lumi-sm-11 {
    width: 91.7%; }
  .lumi-sm-12 {
    width: 100%; } }

@media (min-width: 835.99px) and (max-width: 1023.98px) {
  .container {
    max-width: 1000px !important; }
  .lumi-lg-1 {
    width: 8.3333333%; }
  .lumi-lg-2 {
    width: 16.666666667%; }
  .lumi-lg-3 {
    width: 25%; }
  .lumi-lg-4 {
    width: 33.3333333%; }
  .lumi-lg-5 {
    width: 41.6666667%; }
  .lumi-lg-6 {
    width: 50%; }
  .lumi-lg-7 {
    width: 58.3333333%; }
  .lumi-lg-8 {
    width: 66.6666666%; }
  .lumi-lg-9 {
    width: 75%; }
  .lumi-lg-10 {
    width: 83.3333333%; }
  .lumi-lg-11 {
    width: 91.7%; }
  .lumi-lg-12 {
    width: 100%; } }

@media (min-width: 1024px) and (max-width: 1279.98px) {
  .container {
    max-width: 960px !important; }
  .lumi-xlg-1 {
    width: 8.3333333%; }
  .lumi-xlg-2 {
    width: 16.666666667%; }
  .lumi-xlg-3 {
    width: 25%; }
  .lumi-xlg-4 {
    width: 33.3333333%; }
  .lumi-xlg-5 {
    width: 41.6666667%; }
  .lumi-xlg-6 {
    width: 50%; }
  .lumi-xlg-7 {
    width: 58.3333333%; }
  .lumi-xlg-8 {
    width: 66.6666666%; }
  .lumi-xlg-9 {
    width: 75%; }
  .lumi-xlg-10 {
    width: 83.3333333%; }
  .lumi-xlg-11 {
    width: 91.7%; }
  .lumi-xlg-12 {
    width: 100%; } }

#lumisoft-front .frontendlumisoftnomatch {
  padding-top: 10% !important;
  margin: 0 !important;
  width: 100%;
  height: 100%; }

#lumisoft-front .frontendlumisoftnomatch .lumisoftnomatchwrapper {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  height: 500px; }

#lumisoft-front .frontendlumisoftnomatch .lumisoftnomatchwrapper .lumisoftnomatchtitle {
  font-size: 170px !important;
  margin-bottom: 50px !important;
  line-height: 1;
  color: #213e96;
  padding-top: 40px; }

#lumisoft-front .frontendlumisoftnomatch .lumisoftnomatchwrapper .textUppercase {
  text-transform: uppercase !important; }

#lumisoft-front .frontendlumisoftnomatch .lumisoftnomatchwrapper .lead {
  font-size: 16px;
  line-height: 32px;
  font-weight: 300; }

@media (max-width: 767.98px) {
  #lumisoft-front .frontendlumisoftnomatch {
    padding-top: 30% !important; } }

@media (min-width: 767.99px) and (max-width: 835.98px) {
  #lumisoft-front .frontendlumisoftnomatch {
    padding-top: 20% !important; } }

@media (min-width: 1024px) and (max-width: 1279.98px) {
  #lumisoft-front .frontendlumisoftnomatch {
    padding-top: 15% !important; } }

.lumi-md-1 {
  width: 8.3333333%; }

.lumi-md-2 {
  width: 16.666666667%; }

.lumi-md-3 {
  width: 25%; }

.lumi-md-4 {
  width: 33.3333333%; }

.lumi-md-5 {
  width: 41.6666667%; }

.lumi-md-6 {
  width: 50%; }

.lumi-md-7 {
  width: 58.3333333%; }

.lumi-md-8 {
  width: 66.6666666%; }

.lumi-md-9 {
  width: 75%; }

.lumi-md-10 {
  width: 83.3333333%; }

.lumi-md-12 {
  width: 100%; }

.lumi-md-1, .lumi-md-2, .lumi-md-3, .lumi-md-4, .lumi-md-5, .lumi-md-6, .lumi-md-7, .lumi-md-8, .lumi-md-9, .lumi-md-10, .lumi-md-11, .lumi-md-12 {
  float: left; }

.lumi-xs-1, .lumi-sm-1, .lumi-md-1, .lumi-lg-1, .lumi-xlg-1,
.lumi-xs-2, .lumi-sm-2, .lumi-md-2, .lumi-lg-2, .lumi-xlg-2,
.lumi-xs-3, .lumi-sm-3, .lumi-md-3, .lumi-lg-3, .lumi-xlg-3,
.lumi-xs-4, .lumi-sm-4, .lumi-md-4, .lumi-lg-4, .lumi-xlg-4,
.lumi-xs-5, .lumi-sm-5, .lumi-md-5, .lumi-lg-5, .lumi-xlg-5,
.lumi-xs-6, .lumi-sm-6, .lumi-md-6, .lumi-lg-6, .lumi-xlg-6,
.lumi-xs-7, .lumi-sm-7, .lumi-md-7, .lumi-lg-7, .lumi-xlg-7,
.lumi-xs-8, .lumi-sm-8, .lumi-md-8, .lumi-lg-8, .lumi-xlg-8,
.lumi-xs-9, .lumi-sm-9, .lumi-md-9, .lumi-lg-9, .lumi-xlg-9,
.lumi-xs-10, .lumi-sm-10, .lumi-md-10, .lumi-lg-10, .lumi-xlg-10,
.lumi-xs-11, .lumi-sm-11, .lumi-md-11, .lumi-lg-11, .lumi-xlg-11,
.lumi-xs-12, .lumi-sm-12, .lumi-md-12, .lumi-lg-12, .lumi-xlg-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.container {
  width: 1140px;
  margin: 0 auto; }

.containerfluid {
  width: 100%;
  padding-right: 30px;
  padding-left: 30px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.alignitemscenter {
  align-items: center !important; }

@media (max-width: 767.98px) {
  .containerfluid {
    padding-left: 15px;
    padding-right: 15px; }
  .hiddenXs {
    display: none !important; }
  .container {
    width: 320px !important; }
  .lumi-xs-1 {
    width: 8.3333333%; }
  .lumi-xs-2 {
    width: 16.666666667%; }
  .lumi-xs-3 {
    width: 25%; }
  .lumi-xs-4 {
    width: 33.3333333%; }
  .lumi-xs-5 {
    width: 41.6666667%; }
  .lumi-xs-6 {
    width: 50%; }
  .lumi-xs-7 {
    width: 58.3333333%; }
  .lumi-xs-8 {
    width: 66.6666666%; }
  .lumi-xs-9 {
    width: 75%; }
  .lumi-xs-10 {
    width: 83.3333333%; }
  .lumi-xs-11 {
    width: 91.7%; }
  .lumi-xs-12 {
    width: 100%; } }

@media (min-width: 767.99px) and (max-width: 835.98px) {
  .container {
    max-width: 750px !important; }
  .lumi-sm-1 {
    width: 8.3333333%; }
  .lumi-sm-2 {
    width: 16.666666667%; }
  .lumi-sm-3 {
    width: 25%; }
  .lumi-sm-4 {
    width: 33.3333333%; }
  .lumi-sm-5 {
    width: 41.6666667%; }
  .lumi-sm-6 {
    width: 50%; }
  .lumi-sm-7 {
    width: 58.3333333%; }
  .lumi-sm-8 {
    width: 66.6666666%; }
  .lumi-sm-9 {
    width: 75%; }
  .lumi-sm-10 {
    width: 83.3333333%; }
  .lumi-sm-11 {
    width: 91.7%; }
  .lumi-sm-12 {
    width: 100%; } }

@media (min-width: 835.99px) and (max-width: 1023.98px) {
  .container {
    max-width: 1000px !important; }
  .lumi-lg-1 {
    width: 8.3333333%; }
  .lumi-lg-2 {
    width: 16.666666667%; }
  .lumi-lg-3 {
    width: 25%; }
  .lumi-lg-4 {
    width: 33.3333333%; }
  .lumi-lg-5 {
    width: 41.6666667%; }
  .lumi-lg-6 {
    width: 50%; }
  .lumi-lg-7 {
    width: 58.3333333%; }
  .lumi-lg-8 {
    width: 66.6666666%; }
  .lumi-lg-9 {
    width: 75%; }
  .lumi-lg-10 {
    width: 83.3333333%; }
  .lumi-lg-11 {
    width: 91.7%; }
  .lumi-lg-12 {
    width: 100%; } }

@media (min-width: 1024px) and (max-width: 1279.98px) {
  .container {
    max-width: 960px !important; }
  .lumi-xlg-1 {
    width: 8.3333333%; }
  .lumi-xlg-2 {
    width: 16.666666667%; }
  .lumi-xlg-3 {
    width: 25%; }
  .lumi-xlg-4 {
    width: 33.3333333%; }
  .lumi-xlg-5 {
    width: 41.6666667%; }
  .lumi-xlg-6 {
    width: 50%; }
  .lumi-xlg-7 {
    width: 58.3333333%; }
  .lumi-xlg-8 {
    width: 66.6666666%; }
  .lumi-xlg-9 {
    width: 75%; }
  .lumi-xlg-10 {
    width: 83.3333333%; }
  .lumi-xlg-11 {
    width: 91.7%; }
  .lumi-xlg-12 {
    width: 100%; } }

/***********************************************
                FRONT HEADER
***********************************************/
.frontendwebsite.topBar {
  padding: 30px 25px;
  height: 127px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 101;
  -webkit-transition: margin-left 0.3s ease;
  transition: margin-left 0.3s ease;
  -webkit-transition: height .2s ease-in-out;
  -o-transition: height .2s ease-in-out;
  transition: height .2s ease-in-out; }

.frontendwebsite.topBar.transparent {
  background-color: transparent; }

.frontendwebsite.topBar.white {
  background-color: #ffffff;
  -webkit-box-shadow: 4px 0 5px rgba(0, 0, 0, 0.3);
  box-shadow: 4px 0 5px rgba(0, 0, 0, 0.3);
  height: 65px;
  -webkit-transition: height .2s ease-in-out;
  -o-transition: height .2s ease-in-out;
  transition: height .2s ease-in-out; }

.frontendwebsite.topBar.black {
  background-color: #222222;
  height: 65px;
  -webkit-transition: height .2s ease-in-out;
  -o-transition: height .2s ease-in-out;
  transition: height .2s ease-in-out; }

.frontendwebsite .topBarLeft {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center; }

.frontendwebsite .topBarRight {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  flex-direction: row-reverse; }

header.frontendwebsite .logo {
  /* font-family: 'Monoton', cursive;
    color: #ffffff;
    font-size: 40px;
    */
  padding: 0;
  margin-top: 0;
  margin-left: 1rem; }

header.frontendwebsite .clear {
  flex: 1; }

header.frontendwebsite .toggleButton .toggle_button {
  display: flex;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0; }

header.frontendwebsite .toggleButton.white .toggle_button svg {
  font-size: 24px;
  color: #222222; }

header.frontendwebsite .toggleButton .toggle_button svg {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.8); }

header.frontendwebsite .navigation_items ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex; }

header.frontendwebsite .navigation_items li {
  position: relative;
  float: left;
  display: block; }

header.frontendwebsite .navigation_items a {
  color: #222222;
  text-decoration: none;
  display: inline-block;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 1rem 1rem 0.6rem 1rem;
  border-bottom: 3px solid transparent;
  background-color: transparent !important;
  -webkit-transition: .2s linear;
  transition: .2s linear; }

header.frontendwebsite .navigation_items a:hover,
header.frontendwebsite .navigation_items a:active,
header.frontendwebsite .navigation_items a.active {
  color: #213e96; }

.frontendwebsite.white .navigation_items ul li button {
  color: #555; }

.frontendwebsite.white .navigation_items ul li button:hover,
.frontendwebsite.white .navigation_items ul li button:active,
.frontendwebsite.white .navigation_items ul li button.active {
  color: #213e96; }

header.frontendwebsite .navigation_items ul li button {
  color: #222222;
  outline: none;
  white-space: nowrap;
  text-decoration: none;
  border: 0;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 1rem 1rem 0.6rem 1rem;
  border-bottom: 3px solid transparent;
  background-color: transparent !important;
  -webkit-transition: .2s linear;
  transition: .2s linear; }

header.frontendwebsite .navigation_items ul li button:hover,
header.frontendwebsite .navigation_items ul li button:active,
header.frontendwebsite .navigation_items ul li button.active {
  color: #aaa; }

header.frontendwebsite .navigation_items .icon {
  font-size: 18px; }

header.frontendwebsite .navigation_items .itemContent {
  align-items: center;
  display: table-row; }

header.frontendwebsite .navigation_items .itemIcon {
  padding: 7px 3px; }

header.frontendwebsite .navigation_items .itemInner {
  display: table-cell;
  width: 100%;
  vertical-align: middle;
  white-space: nowrap;
  align-items: center;
  padding: 10px 5px 10px 5px; }

/***********************************************
                FRONT SIDEDRAWER
***********************************************/
#frontendsidedrawer.sideDrawer .sideDrawerLogo {
  padding: 3px;
  text-align: center;
  background-color: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3); }

#frontendsidedrawer.sideDrawer {
  height: 100%;
  background: #ffffff;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 70%;
  max-width: 400px;
  z-index: 1050;
  -webkit-transform: translateX(-110%);
  -moz-transform: translateX(-110%);
  -ms-transform: translateX(-110%);
  transform: translateX(-110%);
  transition: transform 0.2s ease;
  will-change: transform; }

#frontendsidedrawer.sideDrawer.open {
  transform: translateX(0);
  overflow-y: auto;
  top: 0%;
  bottom: 0%;
  position: fixed; }

#frontendsidedrawer.sideDrawer.open ul {
  max-height: 50vh; }

#frontendsidedrawer.sideDrawer ul {
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column; }

#frontendsidedrawer.sideDrawer a, #frontendsidedrawer.sideDrawer .icon {
  color: #878787;
  text-decoration: none;
  font-size: 1.2rem; }

#frontendsidedrawer.sideDrawer a:hover,
#frontendsidedrawer.sideDrawer a:active {
  color: #ffffff; }

#frontendsidedrawer.sideDrawer > ul > li > a {
  border: none;
  display: block;
  position: relative;
  padding: 5px;
  border-radius: 0;
  margin: 0;
  text-align: -webkit-match-parent; }

#frontendsidedrawer.sideDrawer > ul > li > a.active {
  background-color: #dddddd;
  border-right: 7px solid #213e96; }

#frontendsidedrawer.sideDrawer > ul > li a.active,
#frontendsidedrawer.sideDrawer > ul > li a.active .icon {
  color: #222222; }

#frontendsidedrawer.sideDrawer .itemContent {
  align-items: center;
  display: table-row; }

#frontendsidedrawer.sideDrawer .itemIcon {
  padding: 10px 15px;
  width: 55px; }

#frontendsidedrawer.sideDrawer .itemInner {
  display: table-cell;
  width: 100%;
  vertical-align: middle;
  white-space: nowrap;
  align-items: center;
  padding: 10px 15px 10px 10px; }

#frontendsidedrawer.sideDrawer .version {
  position: absolute;
  font-size: 0.8rem;
  font-family: "Roboto", sans-serif;
  display: block;
  color: #878787;
  padding: 11px 15px;
  bottom: 10px;
  right: 10px; }

/***********************************************
            FRONT HEADER RESPONSIVE
***********************************************/
@media (min-width: 767.99px) and (max-width: 835.98px) {
  header.frontendwebsite .toggleButton {
    display: none; } }

@media (min-width: 850px) {
  header.frontendwebsite .toggleButton {
    display: none; } }

@media (min-width: 768px) {
  header.frontendwebsite .logo {
    margin-left: 0; }
  header.frontendwebsite .logo .logo_img.pcHidden {
    display: none; }
  header.frontendwebsite .sideDrawer {
    display: none; }
  #frontendsidedrawer.sideDrawer {
    display: none; } }

@media (max-width: 767.98px) {
  .frontendwebsite.topBar {
    padding: 15px 25px !important;
    height: 65px !important; }
  header.frontendwebsite .logo .logo_img.mobileHidden, header.frontendwebsite .topBarRight .mobileDisplayNone {
    display: none; }
  #frontendsidedrawer .sideDrawerLogo {
    padding: 7px !important; }
  #frontendsidedrawer.sideDrawer ul {
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column; }
  #frontendsidedrawer.sideDrawer > ul > li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3); }
  #frontendsidedrawer.sideDrawer > ul > li:last-child {
    border: 0; }
  #frontendsidedrawer.sideDrawer > ul > li > button.active {
    background-color: #181816;
    border-right: 4px solid #7266ba; }
  #frontendsidedrawer.sideDrawer > ul > li > button {
    width: 100%;
    border: none;
    display: flex;
    position: relative;
    padding: 5px;
    border-radius: 0;
    margin: 0;
    text-align: -webkit-match-parent; }
  #frontendsidedrawer.sideDrawer .icon, #frontendsidedrawer.sideDrawer button, #frontendsidedrawer.sideDrawer a {
    color: #222222;
    text-decoration: none;
    font-size: 1.2rem;
    background-color: #ffffff; }
  #frontendsidedrawer.sideDrawer .itemContent {
    display: flex; }
  #frontendsidedrawer.sideDrawer .itemIcon {
    padding: 10px 15px;
    width: 55px;
    display: flex; }
  #frontendsidedrawer.sideDrawer > ul > li button.active, #frontendsidedrawer.sideDrawer > ul > li button.active .icon {
    color: #fff; }
  #frontendsidedrawer svg:not(:root).svg-inline--fa {
    overflow: visible; }
  #frontendsidedrawer.sideDrawer .itemInner {
    display: table-cell;
    width: 100%;
    vertical-align: middle;
    white-space: nowrap;
    align-items: center;
    padding: 10px 15px 10px 10px; }
  #frontendsidedrawer.sideDrawer .itemInner {
    white-space: nowrap; } }

.lumi-md-1 {
  width: 8.3333333%; }

.lumi-md-2 {
  width: 16.666666667%; }

.lumi-md-3 {
  width: 25%; }

.lumi-md-4 {
  width: 33.3333333%; }

.lumi-md-5 {
  width: 41.6666667%; }

.lumi-md-6 {
  width: 50%; }

.lumi-md-7 {
  width: 58.3333333%; }

.lumi-md-8 {
  width: 66.6666666%; }

.lumi-md-9 {
  width: 75%; }

.lumi-md-10 {
  width: 83.3333333%; }

.lumi-md-12 {
  width: 100%; }

.lumi-md-1, .lumi-md-2, .lumi-md-3, .lumi-md-4, .lumi-md-5, .lumi-md-6, .lumi-md-7, .lumi-md-8, .lumi-md-9, .lumi-md-10, .lumi-md-11, .lumi-md-12 {
  float: left; }

.lumi-xs-1, .lumi-sm-1, .lumi-md-1, .lumi-lg-1, .lumi-xlg-1,
.lumi-xs-2, .lumi-sm-2, .lumi-md-2, .lumi-lg-2, .lumi-xlg-2,
.lumi-xs-3, .lumi-sm-3, .lumi-md-3, .lumi-lg-3, .lumi-xlg-3,
.lumi-xs-4, .lumi-sm-4, .lumi-md-4, .lumi-lg-4, .lumi-xlg-4,
.lumi-xs-5, .lumi-sm-5, .lumi-md-5, .lumi-lg-5, .lumi-xlg-5,
.lumi-xs-6, .lumi-sm-6, .lumi-md-6, .lumi-lg-6, .lumi-xlg-6,
.lumi-xs-7, .lumi-sm-7, .lumi-md-7, .lumi-lg-7, .lumi-xlg-7,
.lumi-xs-8, .lumi-sm-8, .lumi-md-8, .lumi-lg-8, .lumi-xlg-8,
.lumi-xs-9, .lumi-sm-9, .lumi-md-9, .lumi-lg-9, .lumi-xlg-9,
.lumi-xs-10, .lumi-sm-10, .lumi-md-10, .lumi-lg-10, .lumi-xlg-10,
.lumi-xs-11, .lumi-sm-11, .lumi-md-11, .lumi-lg-11, .lumi-xlg-11,
.lumi-xs-12, .lumi-sm-12, .lumi-md-12, .lumi-lg-12, .lumi-xlg-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.container {
  width: 1140px;
  margin: 0 auto; }

.containerfluid {
  width: 100%;
  padding-right: 30px;
  padding-left: 30px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.alignitemscenter {
  align-items: center !important; }

@media (max-width: 767.98px) {
  .containerfluid {
    padding-left: 15px;
    padding-right: 15px; }
  .hiddenXs {
    display: none !important; }
  .container {
    width: 320px !important; }
  .lumi-xs-1 {
    width: 8.3333333%; }
  .lumi-xs-2 {
    width: 16.666666667%; }
  .lumi-xs-3 {
    width: 25%; }
  .lumi-xs-4 {
    width: 33.3333333%; }
  .lumi-xs-5 {
    width: 41.6666667%; }
  .lumi-xs-6 {
    width: 50%; }
  .lumi-xs-7 {
    width: 58.3333333%; }
  .lumi-xs-8 {
    width: 66.6666666%; }
  .lumi-xs-9 {
    width: 75%; }
  .lumi-xs-10 {
    width: 83.3333333%; }
  .lumi-xs-11 {
    width: 91.7%; }
  .lumi-xs-12 {
    width: 100%; } }

@media (min-width: 767.99px) and (max-width: 835.98px) {
  .container {
    max-width: 750px !important; }
  .lumi-sm-1 {
    width: 8.3333333%; }
  .lumi-sm-2 {
    width: 16.666666667%; }
  .lumi-sm-3 {
    width: 25%; }
  .lumi-sm-4 {
    width: 33.3333333%; }
  .lumi-sm-5 {
    width: 41.6666667%; }
  .lumi-sm-6 {
    width: 50%; }
  .lumi-sm-7 {
    width: 58.3333333%; }
  .lumi-sm-8 {
    width: 66.6666666%; }
  .lumi-sm-9 {
    width: 75%; }
  .lumi-sm-10 {
    width: 83.3333333%; }
  .lumi-sm-11 {
    width: 91.7%; }
  .lumi-sm-12 {
    width: 100%; } }

@media (min-width: 835.99px) and (max-width: 1023.98px) {
  .container {
    max-width: 1000px !important; }
  .lumi-lg-1 {
    width: 8.3333333%; }
  .lumi-lg-2 {
    width: 16.666666667%; }
  .lumi-lg-3 {
    width: 25%; }
  .lumi-lg-4 {
    width: 33.3333333%; }
  .lumi-lg-5 {
    width: 41.6666667%; }
  .lumi-lg-6 {
    width: 50%; }
  .lumi-lg-7 {
    width: 58.3333333%; }
  .lumi-lg-8 {
    width: 66.6666666%; }
  .lumi-lg-9 {
    width: 75%; }
  .lumi-lg-10 {
    width: 83.3333333%; }
  .lumi-lg-11 {
    width: 91.7%; }
  .lumi-lg-12 {
    width: 100%; } }

@media (min-width: 1024px) and (max-width: 1279.98px) {
  .container {
    max-width: 960px !important; }
  .lumi-xlg-1 {
    width: 8.3333333%; }
  .lumi-xlg-2 {
    width: 16.666666667%; }
  .lumi-xlg-3 {
    width: 25%; }
  .lumi-xlg-4 {
    width: 33.3333333%; }
  .lumi-xlg-5 {
    width: 41.6666667%; }
  .lumi-xlg-6 {
    width: 50%; }
  .lumi-xlg-7 {
    width: 58.3333333%; }
  .lumi-xlg-8 {
    width: 66.6666666%; }
  .lumi-xlg-9 {
    width: 75%; }
  .lumi-xlg-10 {
    width: 83.3333333%; }
  .lumi-xlg-11 {
    width: 91.7%; }
  .lumi-xlg-12 {
    width: 100%; } }

/***********************************************
            FRONT SLIDER & CAROUSEL
***********************************************/
body {
  font-family: "Roboto", sans-serif;
  color: #d3d3d3;
  background: #343434; }

a {
  color: #359144;
  text-decoration: none;
  background-color: transparent; }

#lumisoft-front .carouselimage {
  background-size: cover !important; }

#lumisoft-front .carouselimage img {
  width: 100%; }

.hometitle {
  position: absolute;
  bottom: 10%;
  left: 45%;
  width: 1000px;
  height: 160px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

.hometitlemobile {
  position: absolute;
  bottom: 50%;
  left: 50%;
  width: 100%;
  padding: 0px 10px;
  height: 160px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

.hometitle .big_white {
  color: #222;
  font-size: 45px;
  margin: 10px 15px 0px 15px;
  text-transform: uppercase;
  line-height: 70px;
  font-weight: 700; }

.hometitlemobile .big_whitemobile {
  color: #222;
  font-size: 25px;
  margin: 10px 15px;
  text-transform: uppercase;
  line-height: 30px;
  font-weight: 700; }

.hometitlemobile .hero-copy {
  color: #222;
  margin: 0 15px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 300; }

.hometitle .hero-copy {
  color: #222;
  margin: 0 15px;
  font-size: 22px;
  line-height: 32px;
  font-weight: 300; }

.hometitle .btn-holder {
  display: block;
  margin: 30px 15px; }

.big_white span {
  color: #213e96; }

.big_whitemobile span {
  color: #213e96;
  display: block; }

.lm-button {
  border: 1px solid #213e96;
  color: #213e96; }

.lm-button {
  font-family: 'Montserrat', 'Open Sans', sans-serif;
  font-size: 13px;
  letter-spacing: 1.5px;
  color: #fff;
  display: inline-block;
  padding: 10px 36px;
  border: 1px solid #888;
  border-radius: 5px;
  text-transform: uppercase;
  border: 1px solid #213e96;
  color: #213e96; }

.slidercontainer {
  width: 100%;
  margin: 0 auto; }

.carouselimage {
  background-size: cover !important; }

@media (max-width: 767.98px) {
  .hometitlemobile {
    position: absolute;
    top: 60%;
    left: 50%;
    width: 100%;
    padding: 0px 10px;
    height: 160px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); } }

@media (min-width: 767.99px) and (max-width: 835.98px) {
  .hometitle {
    position: absolute;
    top: 60%;
    left: 50%;
    width: 600px;
    height: 160px;
    transform: translate(-50%, -50%); }
  .hometitle .big_white {
    color: #222;
    font-size: 45px;
    margin: 10px 15px;
    text-transform: uppercase;
    line-height: 50px;
    font-weight: 700; } }

@media (min-width: 835.99px) and (max-width: 1023.98px) {
  .slidercontainer {
    height: 500px !important;
    overflow: hidden; }
  .hometitle {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 600px;
    height: 160px;
    transform: translate(-50%, -50%); }
  .hometitle .big_white {
    color: #222;
    font-size: 45px;
    margin: 10px 15px;
    text-transform: uppercase;
    line-height: 50px;
    font-weight: 700; } }

.lumi-md-1 {
  width: 8.3333333%; }

.lumi-md-2 {
  width: 16.666666667%; }

.lumi-md-3 {
  width: 25%; }

.lumi-md-4 {
  width: 33.3333333%; }

.lumi-md-5 {
  width: 41.6666667%; }

.lumi-md-6 {
  width: 50%; }

.lumi-md-7 {
  width: 58.3333333%; }

.lumi-md-8 {
  width: 66.6666666%; }

.lumi-md-9 {
  width: 75%; }

.lumi-md-10 {
  width: 83.3333333%; }

.lumi-md-12 {
  width: 100%; }

.lumi-md-1, .lumi-md-2, .lumi-md-3, .lumi-md-4, .lumi-md-5, .lumi-md-6, .lumi-md-7, .lumi-md-8, .lumi-md-9, .lumi-md-10, .lumi-md-11, .lumi-md-12 {
  float: left; }

.lumi-xs-1, .lumi-sm-1, .lumi-md-1, .lumi-lg-1, .lumi-xlg-1,
.lumi-xs-2, .lumi-sm-2, .lumi-md-2, .lumi-lg-2, .lumi-xlg-2,
.lumi-xs-3, .lumi-sm-3, .lumi-md-3, .lumi-lg-3, .lumi-xlg-3,
.lumi-xs-4, .lumi-sm-4, .lumi-md-4, .lumi-lg-4, .lumi-xlg-4,
.lumi-xs-5, .lumi-sm-5, .lumi-md-5, .lumi-lg-5, .lumi-xlg-5,
.lumi-xs-6, .lumi-sm-6, .lumi-md-6, .lumi-lg-6, .lumi-xlg-6,
.lumi-xs-7, .lumi-sm-7, .lumi-md-7, .lumi-lg-7, .lumi-xlg-7,
.lumi-xs-8, .lumi-sm-8, .lumi-md-8, .lumi-lg-8, .lumi-xlg-8,
.lumi-xs-9, .lumi-sm-9, .lumi-md-9, .lumi-lg-9, .lumi-xlg-9,
.lumi-xs-10, .lumi-sm-10, .lumi-md-10, .lumi-lg-10, .lumi-xlg-10,
.lumi-xs-11, .lumi-sm-11, .lumi-md-11, .lumi-lg-11, .lumi-xlg-11,
.lumi-xs-12, .lumi-sm-12, .lumi-md-12, .lumi-lg-12, .lumi-xlg-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.container {
  width: 1140px;
  margin: 0 auto; }

.containerfluid {
  width: 100%;
  padding-right: 30px;
  padding-left: 30px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.alignitemscenter {
  align-items: center !important; }

@media (max-width: 767.98px) {
  .containerfluid {
    padding-left: 15px;
    padding-right: 15px; }
  .hiddenXs {
    display: none !important; }
  .container {
    width: 320px !important; }
  .lumi-xs-1 {
    width: 8.3333333%; }
  .lumi-xs-2 {
    width: 16.666666667%; }
  .lumi-xs-3 {
    width: 25%; }
  .lumi-xs-4 {
    width: 33.3333333%; }
  .lumi-xs-5 {
    width: 41.6666667%; }
  .lumi-xs-6 {
    width: 50%; }
  .lumi-xs-7 {
    width: 58.3333333%; }
  .lumi-xs-8 {
    width: 66.6666666%; }
  .lumi-xs-9 {
    width: 75%; }
  .lumi-xs-10 {
    width: 83.3333333%; }
  .lumi-xs-11 {
    width: 91.7%; }
  .lumi-xs-12 {
    width: 100%; } }

@media (min-width: 767.99px) and (max-width: 835.98px) {
  .container {
    max-width: 750px !important; }
  .lumi-sm-1 {
    width: 8.3333333%; }
  .lumi-sm-2 {
    width: 16.666666667%; }
  .lumi-sm-3 {
    width: 25%; }
  .lumi-sm-4 {
    width: 33.3333333%; }
  .lumi-sm-5 {
    width: 41.6666667%; }
  .lumi-sm-6 {
    width: 50%; }
  .lumi-sm-7 {
    width: 58.3333333%; }
  .lumi-sm-8 {
    width: 66.6666666%; }
  .lumi-sm-9 {
    width: 75%; }
  .lumi-sm-10 {
    width: 83.3333333%; }
  .lumi-sm-11 {
    width: 91.7%; }
  .lumi-sm-12 {
    width: 100%; } }

@media (min-width: 835.99px) and (max-width: 1023.98px) {
  .container {
    max-width: 1000px !important; }
  .lumi-lg-1 {
    width: 8.3333333%; }
  .lumi-lg-2 {
    width: 16.666666667%; }
  .lumi-lg-3 {
    width: 25%; }
  .lumi-lg-4 {
    width: 33.3333333%; }
  .lumi-lg-5 {
    width: 41.6666667%; }
  .lumi-lg-6 {
    width: 50%; }
  .lumi-lg-7 {
    width: 58.3333333%; }
  .lumi-lg-8 {
    width: 66.6666666%; }
  .lumi-lg-9 {
    width: 75%; }
  .lumi-lg-10 {
    width: 83.3333333%; }
  .lumi-lg-11 {
    width: 91.7%; }
  .lumi-lg-12 {
    width: 100%; } }

@media (min-width: 1024px) and (max-width: 1279.98px) {
  .container {
    max-width: 960px !important; }
  .lumi-xlg-1 {
    width: 8.3333333%; }
  .lumi-xlg-2 {
    width: 16.666666667%; }
  .lumi-xlg-3 {
    width: 25%; }
  .lumi-xlg-4 {
    width: 33.3333333%; }
  .lumi-xlg-5 {
    width: 41.6666667%; }
  .lumi-xlg-6 {
    width: 50%; }
  .lumi-xlg-7 {
    width: 58.3333333%; }
  .lumi-xlg-8 {
    width: 66.6666666%; }
  .lumi-xlg-9 {
    width: 75%; }
  .lumi-xlg-10 {
    width: 83.3333333%; }
  .lumi-xlg-11 {
    width: 91.7%; }
  .lumi-xlg-12 {
    width: 100%; } }

#lumisoft-front .floatingbutton {
  width: 50px;
  height: 50px;
  line-height: 35px;
  padding: 0;
  position: fixed;
  bottom: 25px;
  right: 20px;
  border-radius: 50%;
  border: none;
  text-align: center;
  outline: 0;
  z-index: 1021;
  background-color: #41b053; }

#lumisoft-front .floatingbutton .buttonicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-weight: 400;
  line-height: 45px;
  padding: 9px;
  color: #FAFAFA; }

#lumisoft-front .floatingbuttontotop {
  width: 50px;
  height: 50px;
  line-height: 35px;
  padding: 0;
  position: fixed;
  bottom: 100px;
  right: 20px;
  border-radius: 50%;
  border: none;
  text-align: center;
  outline: 0;
  z-index: 1021;
  background-color: #213e96;
  border: 1px solid #213e96; }

#lumisoft-front .floatingbuttontotop .buttonicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-weight: 400;
  line-height: 45px;
  padding: 9px;
  color: #ffffff; }

.lumi-md-1 {
  width: 8.3333333%; }

.lumi-md-2 {
  width: 16.666666667%; }

.lumi-md-3 {
  width: 25%; }

.lumi-md-4 {
  width: 33.3333333%; }

.lumi-md-5 {
  width: 41.6666667%; }

.lumi-md-6 {
  width: 50%; }

.lumi-md-7 {
  width: 58.3333333%; }

.lumi-md-8 {
  width: 66.6666666%; }

.lumi-md-9 {
  width: 75%; }

.lumi-md-10 {
  width: 83.3333333%; }

.lumi-md-12 {
  width: 100%; }

.lumi-md-1, .lumi-md-2, .lumi-md-3, .lumi-md-4, .lumi-md-5, .lumi-md-6, .lumi-md-7, .lumi-md-8, .lumi-md-9, .lumi-md-10, .lumi-md-11, .lumi-md-12 {
  float: left; }

.lumi-xs-1, .lumi-sm-1, .lumi-md-1, .lumi-lg-1, .lumi-xlg-1,
.lumi-xs-2, .lumi-sm-2, .lumi-md-2, .lumi-lg-2, .lumi-xlg-2,
.lumi-xs-3, .lumi-sm-3, .lumi-md-3, .lumi-lg-3, .lumi-xlg-3,
.lumi-xs-4, .lumi-sm-4, .lumi-md-4, .lumi-lg-4, .lumi-xlg-4,
.lumi-xs-5, .lumi-sm-5, .lumi-md-5, .lumi-lg-5, .lumi-xlg-5,
.lumi-xs-6, .lumi-sm-6, .lumi-md-6, .lumi-lg-6, .lumi-xlg-6,
.lumi-xs-7, .lumi-sm-7, .lumi-md-7, .lumi-lg-7, .lumi-xlg-7,
.lumi-xs-8, .lumi-sm-8, .lumi-md-8, .lumi-lg-8, .lumi-xlg-8,
.lumi-xs-9, .lumi-sm-9, .lumi-md-9, .lumi-lg-9, .lumi-xlg-9,
.lumi-xs-10, .lumi-sm-10, .lumi-md-10, .lumi-lg-10, .lumi-xlg-10,
.lumi-xs-11, .lumi-sm-11, .lumi-md-11, .lumi-lg-11, .lumi-xlg-11,
.lumi-xs-12, .lumi-sm-12, .lumi-md-12, .lumi-lg-12, .lumi-xlg-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.container {
  width: 1140px;
  margin: 0 auto; }

.containerfluid {
  width: 100%;
  padding-right: 30px;
  padding-left: 30px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.alignitemscenter {
  align-items: center !important; }

@media (max-width: 767.98px) {
  .containerfluid {
    padding-left: 15px;
    padding-right: 15px; }
  .hiddenXs {
    display: none !important; }
  .container {
    width: 320px !important; }
  .lumi-xs-1 {
    width: 8.3333333%; }
  .lumi-xs-2 {
    width: 16.666666667%; }
  .lumi-xs-3 {
    width: 25%; }
  .lumi-xs-4 {
    width: 33.3333333%; }
  .lumi-xs-5 {
    width: 41.6666667%; }
  .lumi-xs-6 {
    width: 50%; }
  .lumi-xs-7 {
    width: 58.3333333%; }
  .lumi-xs-8 {
    width: 66.6666666%; }
  .lumi-xs-9 {
    width: 75%; }
  .lumi-xs-10 {
    width: 83.3333333%; }
  .lumi-xs-11 {
    width: 91.7%; }
  .lumi-xs-12 {
    width: 100%; } }

@media (min-width: 767.99px) and (max-width: 835.98px) {
  .container {
    max-width: 750px !important; }
  .lumi-sm-1 {
    width: 8.3333333%; }
  .lumi-sm-2 {
    width: 16.666666667%; }
  .lumi-sm-3 {
    width: 25%; }
  .lumi-sm-4 {
    width: 33.3333333%; }
  .lumi-sm-5 {
    width: 41.6666667%; }
  .lumi-sm-6 {
    width: 50%; }
  .lumi-sm-7 {
    width: 58.3333333%; }
  .lumi-sm-8 {
    width: 66.6666666%; }
  .lumi-sm-9 {
    width: 75%; }
  .lumi-sm-10 {
    width: 83.3333333%; }
  .lumi-sm-11 {
    width: 91.7%; }
  .lumi-sm-12 {
    width: 100%; } }

@media (min-width: 835.99px) and (max-width: 1023.98px) {
  .container {
    max-width: 1000px !important; }
  .lumi-lg-1 {
    width: 8.3333333%; }
  .lumi-lg-2 {
    width: 16.666666667%; }
  .lumi-lg-3 {
    width: 25%; }
  .lumi-lg-4 {
    width: 33.3333333%; }
  .lumi-lg-5 {
    width: 41.6666667%; }
  .lumi-lg-6 {
    width: 50%; }
  .lumi-lg-7 {
    width: 58.3333333%; }
  .lumi-lg-8 {
    width: 66.6666666%; }
  .lumi-lg-9 {
    width: 75%; }
  .lumi-lg-10 {
    width: 83.3333333%; }
  .lumi-lg-11 {
    width: 91.7%; }
  .lumi-lg-12 {
    width: 100%; } }

@media (min-width: 1024px) and (max-width: 1279.98px) {
  .container {
    max-width: 960px !important; }
  .lumi-xlg-1 {
    width: 8.3333333%; }
  .lumi-xlg-2 {
    width: 16.666666667%; }
  .lumi-xlg-3 {
    width: 25%; }
  .lumi-xlg-4 {
    width: 33.3333333%; }
  .lumi-xlg-5 {
    width: 41.6666667%; }
  .lumi-xlg-6 {
    width: 50%; }
  .lumi-xlg-7 {
    width: 58.3333333%; }
  .lumi-xlg-8 {
    width: 66.6666666%; }
  .lumi-xlg-9 {
    width: 75%; }
  .lumi-xlg-10 {
    width: 83.3333333%; }
  .lumi-xlg-11 {
    width: 91.7%; }
  .lumi-xlg-12 {
    width: 100%; } }

#lumisoft-front section#homepage {
  position: relative;
  overflow: hidden;
  background-color: #ffffff; }

#lumisoft-front section#homepage.normalheader {
  margin-top: 66.6px; }

#lumisoft-front section#homepage.headershow {
  margin-top: 127px; }

#lumisoft-front #homepage .contentwrap {
  position: relative;
  padding: 0 !important; }

#lumisoft-front #homepage .contentwrap .section {
  position: relative;
  margin: 60px 0;
  padding: 60px 0;
  overflow: hidden; }

#lumisoft-front #homepage .contentwrap .headingblock {
  margin-bottom: 0; }

#lumisoft-front #homepage .contentwrap .mainhomeabout.center,
#lumisoft-front #homepage .contentwrap .headingblock.center {
  position: relative !important;
  float: none !important;
  margin-left: auto !important;
  margin-right: auto !important;
  text-align: center !important; }

#lumisoft-front #homepage .contentwrap .headingblock h2 {
  font-size: 30px;
  color: #333;
  letter-spacing: 0 !important;
  font-weight: 600 !important;
  text-transform: none !important;
  margin-bottom: 5px;
  font-family: "Roboto", sans-serif; }

#lumisoft-front #homepage .contentwrap .headingblock h4 {
  font-size: 20px;
  color: #333;
  letter-spacing: 1px !important;
  font-weight: 600 !important;
  text-transform: none !important;
  margin-bottom: 5px;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase !important; }

#lumisoft-front #homepage .contentwrap .headingblock small {
  letter-spacing: 3px !important;
  font-weight: 600 !important;
  text-transform: uppercase !important;
  color: rgba(0, 0, 0, 0.5);
  font-size: 80%;
  margin-bottom: 1rem !important;
  display: block;
  font-family: "Roboto", sans-serif; }

#lumisoft-front #homepage .contentwrap .headingblock p {
  color: rgba(0, 0, 0, 0.8); }

#lumisoft-front #homepage .contentwrap .mainhomeabout p {
  color: rgba(0, 0, 0, 0.8); }

#lumisoft-front #homepage .contentwrap .aboutcardwrapper {
  color: rgba(0, 0, 0, 0.8); }

#lumisoft-front #homepage .contentwrap .aboutcardwrapper .aboutcard {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start; }

#lumisoft-front #homepage .contentwrap .aboutcardwrapper .aboutcard svg {
  width: auto;
  padding: 10px;
  margin-right: 15px;
  font-size: 60px;
  width: 64px; }

#lumisoft-front #homepage .contentwrap .aboutcardwrapper .aboutcard .aboutcardbody {
  -ms-flex: 1;
  flex: 1; }

#lumisoft-front #homepage .contentwrap .aboutcardwrapper .aboutcard .aboutcardbody h5 {
  font-weight: 900; }

#lumisoft-front #homepage .contentwrap .introwrapper {
  color: rgba(0, 0, 0, 0.8); }

#lumisoft-front #homepage .contentwrap .introwrapper h2 {
  font-weight: 900;
  font-size: 2rem;
  margin-bottom: .5rem; }

#lumisoft-front #homepage .contentwrap .introwrapper p {
  font-weight: 500;
  margin-bottom: 1rem; }

#lumisoft-front #homepage .contentwrap .introwrapper button {
  background: transparent;
  border: none; }

#lumisoft-front #homepage .contentwrap .introwrapper button.gallerybutton {
  color: #333;
  font-weight: 700;
  display: inline-block;
  padding: 0;
  -webkit-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  text-transform: uppercase !important; }

#lumisoft-front #homepage .contentwrap .introwrapper button.gallerybutton .buttonicon::after {
  content: " ";
  border-top: 2px solid #333;
  width: 36px;
  height: 7px;
  display: inline-block;
  margin-left: 20px;
  -webkit-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  transform-origin: top left; }

#lumisoft-front #homepage .contentwrap .introwrapper button.gallerybutton:hover .buttonicon::after {
  width: 100px; }

#lumisoft-front #homepage .contentwrap .verticalmiddle {
  display: grid;
  align-content: center; }

#lumisoft-front #homepage .contentwrap .gallerywrapper {
  display: block;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  border-radius: .3rem;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.3);
  transition: transform 0.5s;
  -webkit-transition: transform 0.5s;
  cursor: pointer;
  min-height: 401px; }

#lumisoft-front #homepage .contentwrap .gallerywrapper .gallerythumbnailwrapper {
  width: 100%;
  position: relative;
  height: 183px;
  overflow: hidden; }

#lumisoft-front #homepage .contentwrap .gallerywrapper .gallerythumbnail {
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transition: all 2s linear;
  -o-transition: all 2s linear;
  transition: all 2s linear;
  cursor: pointer; }

#lumisoft-front #homepage .contentwrap .gallerywrapper:hover .gallerythumbnail {
  transform: scale(1.2); }

#lumisoft-front #homepage .contentwrap .gallerywrapper .galleryname {
  margin-top: 15px;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 900;
  line-height: 1.3;
  text-align: center; }

#lumisoft-front #homepage .contentwrap .gallerywrapper .galleryaddress {
  color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  text-align: center;
  font-size: 0.85rem;
  position: relative; }

#lumisoft-front #homepage .contentwrap .gallerywrapper .gallerytext {
  color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  text-align: center;
  font-size: 0.85rem;
  font-style: italic;
  position: absolute;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center; }

@media (max-width: 767.98px) {
  #lumisoft-front #homepage .contentwrap .gallerywrapper {
    margin-top: 50px; }
  #lumisoft-front #homepage .contentwrap .introwrapper button.gallerybutton .buttonicon::after {
    width: 100px; } }

.lumi-md-1 {
  width: 8.3333333%; }

.lumi-md-2 {
  width: 16.666666667%; }

.lumi-md-3 {
  width: 25%; }

.lumi-md-4 {
  width: 33.3333333%; }

.lumi-md-5 {
  width: 41.6666667%; }

.lumi-md-6 {
  width: 50%; }

.lumi-md-7 {
  width: 58.3333333%; }

.lumi-md-8 {
  width: 66.6666666%; }

.lumi-md-9 {
  width: 75%; }

.lumi-md-10 {
  width: 83.3333333%; }

.lumi-md-12 {
  width: 100%; }

.lumi-md-1, .lumi-md-2, .lumi-md-3, .lumi-md-4, .lumi-md-5, .lumi-md-6, .lumi-md-7, .lumi-md-8, .lumi-md-9, .lumi-md-10, .lumi-md-11, .lumi-md-12 {
  float: left; }

.lumi-xs-1, .lumi-sm-1, .lumi-md-1, .lumi-lg-1, .lumi-xlg-1,
.lumi-xs-2, .lumi-sm-2, .lumi-md-2, .lumi-lg-2, .lumi-xlg-2,
.lumi-xs-3, .lumi-sm-3, .lumi-md-3, .lumi-lg-3, .lumi-xlg-3,
.lumi-xs-4, .lumi-sm-4, .lumi-md-4, .lumi-lg-4, .lumi-xlg-4,
.lumi-xs-5, .lumi-sm-5, .lumi-md-5, .lumi-lg-5, .lumi-xlg-5,
.lumi-xs-6, .lumi-sm-6, .lumi-md-6, .lumi-lg-6, .lumi-xlg-6,
.lumi-xs-7, .lumi-sm-7, .lumi-md-7, .lumi-lg-7, .lumi-xlg-7,
.lumi-xs-8, .lumi-sm-8, .lumi-md-8, .lumi-lg-8, .lumi-xlg-8,
.lumi-xs-9, .lumi-sm-9, .lumi-md-9, .lumi-lg-9, .lumi-xlg-9,
.lumi-xs-10, .lumi-sm-10, .lumi-md-10, .lumi-lg-10, .lumi-xlg-10,
.lumi-xs-11, .lumi-sm-11, .lumi-md-11, .lumi-lg-11, .lumi-xlg-11,
.lumi-xs-12, .lumi-sm-12, .lumi-md-12, .lumi-lg-12, .lumi-xlg-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.container {
  width: 1140px;
  margin: 0 auto; }

.containerfluid {
  width: 100%;
  padding-right: 30px;
  padding-left: 30px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.alignitemscenter {
  align-items: center !important; }

@media (max-width: 767.98px) {
  .containerfluid {
    padding-left: 15px;
    padding-right: 15px; }
  .hiddenXs {
    display: none !important; }
  .container {
    width: 320px !important; }
  .lumi-xs-1 {
    width: 8.3333333%; }
  .lumi-xs-2 {
    width: 16.666666667%; }
  .lumi-xs-3 {
    width: 25%; }
  .lumi-xs-4 {
    width: 33.3333333%; }
  .lumi-xs-5 {
    width: 41.6666667%; }
  .lumi-xs-6 {
    width: 50%; }
  .lumi-xs-7 {
    width: 58.3333333%; }
  .lumi-xs-8 {
    width: 66.6666666%; }
  .lumi-xs-9 {
    width: 75%; }
  .lumi-xs-10 {
    width: 83.3333333%; }
  .lumi-xs-11 {
    width: 91.7%; }
  .lumi-xs-12 {
    width: 100%; } }

@media (min-width: 767.99px) and (max-width: 835.98px) {
  .container {
    max-width: 750px !important; }
  .lumi-sm-1 {
    width: 8.3333333%; }
  .lumi-sm-2 {
    width: 16.666666667%; }
  .lumi-sm-3 {
    width: 25%; }
  .lumi-sm-4 {
    width: 33.3333333%; }
  .lumi-sm-5 {
    width: 41.6666667%; }
  .lumi-sm-6 {
    width: 50%; }
  .lumi-sm-7 {
    width: 58.3333333%; }
  .lumi-sm-8 {
    width: 66.6666666%; }
  .lumi-sm-9 {
    width: 75%; }
  .lumi-sm-10 {
    width: 83.3333333%; }
  .lumi-sm-11 {
    width: 91.7%; }
  .lumi-sm-12 {
    width: 100%; } }

@media (min-width: 835.99px) and (max-width: 1023.98px) {
  .container {
    max-width: 1000px !important; }
  .lumi-lg-1 {
    width: 8.3333333%; }
  .lumi-lg-2 {
    width: 16.666666667%; }
  .lumi-lg-3 {
    width: 25%; }
  .lumi-lg-4 {
    width: 33.3333333%; }
  .lumi-lg-5 {
    width: 41.6666667%; }
  .lumi-lg-6 {
    width: 50%; }
  .lumi-lg-7 {
    width: 58.3333333%; }
  .lumi-lg-8 {
    width: 66.6666666%; }
  .lumi-lg-9 {
    width: 75%; }
  .lumi-lg-10 {
    width: 83.3333333%; }
  .lumi-lg-11 {
    width: 91.7%; }
  .lumi-lg-12 {
    width: 100%; } }

@media (min-width: 1024px) and (max-width: 1279.98px) {
  .container {
    max-width: 960px !important; }
  .lumi-xlg-1 {
    width: 8.3333333%; }
  .lumi-xlg-2 {
    width: 16.666666667%; }
  .lumi-xlg-3 {
    width: 25%; }
  .lumi-xlg-4 {
    width: 33.3333333%; }
  .lumi-xlg-5 {
    width: 41.6666667%; }
  .lumi-xlg-6 {
    width: 50%; }
  .lumi-xlg-7 {
    width: 58.3333333%; }
  .lumi-xlg-8 {
    width: 66.6666666%; }
  .lumi-xlg-9 {
    width: 75%; }
  .lumi-xlg-10 {
    width: 83.3333333%; }
  .lumi-xlg-11 {
    width: 91.7%; }
  .lumi-xlg-12 {
    width: 100%; } }

#lumisoft-front #gallerypage {
  color: #222 !important; }

#lumisoft-front #gallerypage .contentwrap {
  position: relative;
  padding: 0 !important; }

#lumisoft-front #gallerypage .contentwrap .section {
  position: relative;
  margin: 60px 0;
  padding: 60px 0;
  overflow: hidden; }

#lumisoft-front #gallerypage .contentwrap .headingblock h2 {
  margin-bottom: .5rem;
  line-height: 1.2;
  font-weight: 900;
  font-size: 2rem;
  text-transform: uppercase; }

#lumisoft-front #gallerypage .contentwrap .headingblock h3 {
  margin-bottom: 1rem;
  line-height: 1.2;
  font-weight: 900;
  font-size: 1.3rem;
  text-transform: uppercase;
  font-family: 'Roboto'; }

#lumisoft-front #gallerypage .contentwrap figure:first-child {
  width: 50%;
  height: 650px;
  float: left;
  position: relative;
  margin: 0;
  overflow: hidden;
  border: 1px solid #fff; }

#lumisoft-front #gallerypage .contentwrap figure {
  float: left;
  width: 25%;
  position: relative;
  height: 325px;
  margin: 0;
  overflow: hidden;
  border: 1px solid #fff; }

#lumisoft-front #gallerypage .contentwrap figure .sectioninside {
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transition: all 2s linear;
  -o-transition: all 2s linear;
  transition: all 2s linear;
  cursor: pointer; }

#lumisoft-front #gallerypage .contentwrap figure .sectioninside:hover {
  transform: scale(1.1); }

#lumisoft-front #gallerypage .contentwrap .detailsitem {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #cccccc;
  min-height: 90px; }

#lumisoft-front #gallerypage .contentwrap .detailsitem .detailheader {
  font-size: .7rem;
  text-transform: uppercase !important; }

#lumisoft-front #gallerypage .contentwrap .detailsitem .detailvalue {
  font-weight: 700; }

#lumisoft-front #gallerypage .contentwrap .denahrumahwrapper {
  width: 100%;
  position: relative;
  height: 550px;
  overflow: hidden; }

#lumisoft-front #gallerypage .contentwrap figure .denahrumah {
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer; }

#lumisoft-front .directionbutton {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: 0;
  background-color: #213e96;
  margin-top: 20px; }

#lumisoft-front .directionbutton .buttontext {
  position: relative;
  display: inline-block;
  font-weight: 400;
  padding: 10px 10px 12px 10px;
  color: #fff; }

#lumisoft-front .directionbutton .buttonicon {
  position: relative;
  display: inline-block;
  font-weight: 400;
  padding: 12px 9px 6px 9px;
  color: #fff; }

.lumi-md-1 {
  width: 8.3333333%; }

.lumi-md-2 {
  width: 16.666666667%; }

.lumi-md-3 {
  width: 25%; }

.lumi-md-4 {
  width: 33.3333333%; }

.lumi-md-5 {
  width: 41.6666667%; }

.lumi-md-6 {
  width: 50%; }

.lumi-md-7 {
  width: 58.3333333%; }

.lumi-md-8 {
  width: 66.6666666%; }

.lumi-md-9 {
  width: 75%; }

.lumi-md-10 {
  width: 83.3333333%; }

.lumi-md-12 {
  width: 100%; }

.lumi-md-1, .lumi-md-2, .lumi-md-3, .lumi-md-4, .lumi-md-5, .lumi-md-6, .lumi-md-7, .lumi-md-8, .lumi-md-9, .lumi-md-10, .lumi-md-11, .lumi-md-12 {
  float: left; }

.lumi-xs-1, .lumi-sm-1, .lumi-md-1, .lumi-lg-1, .lumi-xlg-1,
.lumi-xs-2, .lumi-sm-2, .lumi-md-2, .lumi-lg-2, .lumi-xlg-2,
.lumi-xs-3, .lumi-sm-3, .lumi-md-3, .lumi-lg-3, .lumi-xlg-3,
.lumi-xs-4, .lumi-sm-4, .lumi-md-4, .lumi-lg-4, .lumi-xlg-4,
.lumi-xs-5, .lumi-sm-5, .lumi-md-5, .lumi-lg-5, .lumi-xlg-5,
.lumi-xs-6, .lumi-sm-6, .lumi-md-6, .lumi-lg-6, .lumi-xlg-6,
.lumi-xs-7, .lumi-sm-7, .lumi-md-7, .lumi-lg-7, .lumi-xlg-7,
.lumi-xs-8, .lumi-sm-8, .lumi-md-8, .lumi-lg-8, .lumi-xlg-8,
.lumi-xs-9, .lumi-sm-9, .lumi-md-9, .lumi-lg-9, .lumi-xlg-9,
.lumi-xs-10, .lumi-sm-10, .lumi-md-10, .lumi-lg-10, .lumi-xlg-10,
.lumi-xs-11, .lumi-sm-11, .lumi-md-11, .lumi-lg-11, .lumi-xlg-11,
.lumi-xs-12, .lumi-sm-12, .lumi-md-12, .lumi-lg-12, .lumi-xlg-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.container {
  width: 1140px;
  margin: 0 auto; }

.containerfluid {
  width: 100%;
  padding-right: 30px;
  padding-left: 30px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.alignitemscenter {
  align-items: center !important; }

@media (max-width: 767.98px) {
  .containerfluid {
    padding-left: 15px;
    padding-right: 15px; }
  .hiddenXs {
    display: none !important; }
  .container {
    width: 320px !important; }
  .lumi-xs-1 {
    width: 8.3333333%; }
  .lumi-xs-2 {
    width: 16.666666667%; }
  .lumi-xs-3 {
    width: 25%; }
  .lumi-xs-4 {
    width: 33.3333333%; }
  .lumi-xs-5 {
    width: 41.6666667%; }
  .lumi-xs-6 {
    width: 50%; }
  .lumi-xs-7 {
    width: 58.3333333%; }
  .lumi-xs-8 {
    width: 66.6666666%; }
  .lumi-xs-9 {
    width: 75%; }
  .lumi-xs-10 {
    width: 83.3333333%; }
  .lumi-xs-11 {
    width: 91.7%; }
  .lumi-xs-12 {
    width: 100%; } }

@media (min-width: 767.99px) and (max-width: 835.98px) {
  .container {
    max-width: 750px !important; }
  .lumi-sm-1 {
    width: 8.3333333%; }
  .lumi-sm-2 {
    width: 16.666666667%; }
  .lumi-sm-3 {
    width: 25%; }
  .lumi-sm-4 {
    width: 33.3333333%; }
  .lumi-sm-5 {
    width: 41.6666667%; }
  .lumi-sm-6 {
    width: 50%; }
  .lumi-sm-7 {
    width: 58.3333333%; }
  .lumi-sm-8 {
    width: 66.6666666%; }
  .lumi-sm-9 {
    width: 75%; }
  .lumi-sm-10 {
    width: 83.3333333%; }
  .lumi-sm-11 {
    width: 91.7%; }
  .lumi-sm-12 {
    width: 100%; } }

@media (min-width: 835.99px) and (max-width: 1023.98px) {
  .container {
    max-width: 1000px !important; }
  .lumi-lg-1 {
    width: 8.3333333%; }
  .lumi-lg-2 {
    width: 16.666666667%; }
  .lumi-lg-3 {
    width: 25%; }
  .lumi-lg-4 {
    width: 33.3333333%; }
  .lumi-lg-5 {
    width: 41.6666667%; }
  .lumi-lg-6 {
    width: 50%; }
  .lumi-lg-7 {
    width: 58.3333333%; }
  .lumi-lg-8 {
    width: 66.6666666%; }
  .lumi-lg-9 {
    width: 75%; }
  .lumi-lg-10 {
    width: 83.3333333%; }
  .lumi-lg-11 {
    width: 91.7%; }
  .lumi-lg-12 {
    width: 100%; } }

@media (min-width: 1024px) and (max-width: 1279.98px) {
  .container {
    max-width: 960px !important; }
  .lumi-xlg-1 {
    width: 8.3333333%; }
  .lumi-xlg-2 {
    width: 16.666666667%; }
  .lumi-xlg-3 {
    width: 25%; }
  .lumi-xlg-4 {
    width: 33.3333333%; }
  .lumi-xlg-5 {
    width: 41.6666667%; }
  .lumi-xlg-6 {
    width: 50%; }
  .lumi-xlg-7 {
    width: 58.3333333%; }
  .lumi-xlg-8 {
    width: 66.6666666%; }
  .lumi-xlg-9 {
    width: 75%; }
  .lumi-xlg-10 {
    width: 83.3333333%; }
  .lumi-xlg-11 {
    width: 91.7%; }
  .lumi-xlg-12 {
    width: 100%; } }

#lumisoft-front #frontfooter {
  background-color: #222222;
  color: #F5F5F5; }

#lumisoft-front #frontfooter .frontwidget {
  margin-top: 0;
  position: relative;
  padding: 60px 40px; }

#lumisoft-front #frontfooter .frontwidget.first {
  margin-top: 0;
  position: relative;
  padding: 60px 40px 20px 40px; }

#lumisoft-front #frontfooter .frontwidget h3 {
  font-family: "Roboto", sans-serif;
  margin-bottom: 20px;
  color: #F5F5F5;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 22px; }

#lumisoft-front #frontfooter .frontwidget address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 30px; }

#lumisoft-front #frontfooter .frontwidget abbr[title],
#lumisoft-front #frontfooter .frontwidget abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none; }

#lumisoft-front #frontfooter .frontwidget .footersocial ul {
  padding-top: 5px; }

#lumisoft-front #frontfooter .frontwidget .footersocial ul li {
  display: inline-block;
  text-align: center;
  margin-right: 20px; }

#lumisoft-front #frontfooter .frontwidget .footersocial ul li a {
  line-height: 35px;
  font-size: 35px;
  color: #777777;
  border-radius: 3px;
  display: inline-block;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear; }

#lumisoft-front #frontfooter .frontwidget .footersocial ul li a svg:hover {
  color: #F5F5F5; }

#lumisoft-front #frontfooter .homecopyright {
  display: flex;
  justify-content: center; }

#lumisoft-front #frontfooter .homecopyright p.footercopy {
  margin-bottom: 0;
  font-size: 16px;
  color: #fff;
  padding: 20px 10px;
  margin-bottom: 40px; }

#lumisoft-front #frontfooter .homecopyright p.footercopy a {
  color: #888888; }

#lumisoft-front #frontfooter .homecopyright p.footercopy a:hover {
  color: #F5F5F5; }

@media (max-width: 767.98px) {
  #lumisoft-front #frontfooter .frontwidget {
    margin-top: 0;
    position: relative;
    padding: 30px 10px; }
  #lumisoft-front #frontfooter .frontwidget.first {
    margin-top: 0;
    position: relative;
    padding: 60px 10px 10px 10px; } }

*, ::after, ::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.clearfix:before, .clearfix:after, .dl-horizontal dd:before, .dl-horizontal dd:after, .container:before, .container:after, .container-fluid:before, .container-fluid:after, .row:before, .row:after, .form-horizontal .form-group:before, .form-horizontal .form-group:after, .btn-toolbar:before, .btn-toolbar:after, .btn-group-vertical > .btn-group:before, .btn-group-vertical > .btn-group:after, .nav:before, .nav:after, .navbar:before, .navbar:after, .navbar-header:before, .navbar-header:after, .navbar-collapse:before, .navbar-collapse:after, .pager:before, .pager:after, .panel-body:before, .panel-body:after, .modal-footer:before, .modal-footer:after, #topbar:before, #topbar:after {
  content: " ";
  display: table; }

.clearfix:after, .dl-horizontal dd:after, .container:after, .container-fluid:after, .row:after, .form-horizontal .form-group:after, .btn-toolbar:after, .btn-group-vertical > .btn-group:after, .nav:after, .navbar:after, .navbar-header:after, .navbar-collapse:after, .pager:after, .panel-body:after, .modal-footer:after, #topbar:after {
  clear: both; }

html, body, img, figure {
  max-width: 100%; }

html, body {
  overflow-y: auto;
  overflow-x: hidden; }

img {
  vertical-align: middle;
  border: 0;
  border-style: none; }

body, body #root #lumisoft-blank,
body, body #root #lumisoft-maxi,
body, body #root #lumisoft-login {
  background-color: #fff;
  font-family: Lato, sans-serif; }

body, body #root #lumisoft-blank a,
body #root #lumisoft-maxi a,
body #root #lumisoft-login a {
  text-decoration: none;
  background-color: transparent; }

.h1, h1 {
  font-size: 2.5rem; }

.h3, h3 {
  font-size: 23px; }

.h4, h4 {
  font-size: 1.5rem; }

.h5, h5 {
  font-size: 1.2rem; }

.h6, h6 {
  font-size: 14px;
  font-weight: 400; }

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-bottom: .5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0; }

b, strong {
  font-weight: bolder; }

p {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem; }

.map-container {
  height: 400px;
  width: 100%; }

.map-ref {
  height: 100%; }

.btn {
  font-size: 30px; }

/***********************************************
                    BUTTON
***********************************************/
[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
  cursor: pointer; }

[type=button], [type=reset], [type=submit], button {
  -webkit-appearance: button; }

button, select {
  text-transform: none; }

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

/***********************************************
                    INPUT
***********************************************/
input[type=text], input[type=password], input[type=email], textarea {
  appearance: none !important; }

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  cursor: pointer;
  line-height: normal; }

input[type=checkbox], input[type=radio] {
  margin: 4px 0 0;
  padding: 0; }

input[type="text"], input[type="password"], input[type="datetime"], input[type="daterange"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  display: inline-block;
  padding: 7px 13px;
  width: 100%;
  height: 32px;
  font-size: 15px;
  line-height: 1.5;
  color: #bbbbbb;
  background-color: #343434;
  background-image: none;
  border: 1px solid #1a1a1a;
  border-radius: 4px;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s; }

textarea:hover, input[type="text"]:hover, input[type="password"]:hover, input[type="datetime"]:hover, input[type="daterange"]:hover, input[type="datetime-local"]:hover, input[type="date"]:hover, input[type="month"]:hover, input[type="time"]:hover, input[type="week"]:hover, input[type="number"]:hover, input[type="email"]:hover, input[type="url"]:hover, input[type="search"]:hover, input[type="tel"]:hover, input[type="color"]:hover {
  border-color: #5a5a5a !important; }

textarea:focus, input[type="text"]:focus, input[type="password"]:focus, input[type="datetime"]:focus, input[type="daterange"]:focus, input[type="datetime-local"]:focus, input[type="date"]:focus, input[type="month"]:focus, input[type="time"]:focus, input[type="week"]:focus, input[type="number"]:focus, input[type="email"]:focus, input[type="url"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="color"]:focus {
  box-shadow: none !important;
  border-color: #5a5a5a !important;
  background-color: #1a1a1a !important; }

.disabled {
  pointer-events: none;
  color: rgba(255, 255, 255, 0.2) !important; }
